import ApiArticleCategory from 'types/api/ArticleCategory';
import { Routes } from 'types/routes';

import Model from 'models/Model';

import { withVariables } from 'utils/string';

export default class ArticleCategory implements Model {
    id: string;
    name: string;
    slug: string;
    description: string;
    href: string;

    constructor(data: ApiArticleCategory) {
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
        this.description = data.description;
        this.href = this.getHref();
    }

    getHref = (): string => {
        return withVariables(
            Routes.PublicBlogCategory,
            { articleCategorySlug: this.slug }
        );
    };
}
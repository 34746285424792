import getConfig from 'next/config';

// https://developers.cloudflare.com/images/image-resizing/url-format#options
export interface Options {
    width?: number;
    height?: number;
    dpr?: number,
    fit?: Fit,
    gravity?: Gravity,
    quality?: number,
    format?: Format,
    anim?: boolean,
    sharpen?: number,
    blur?: number,
    onerror?: string,
    metadata?: Metadata;
}

// Aspect ratio width and height
export enum Fit {
    ScaleDown = 'scale-down',
    Contain = 'contain',
    Cover = 'cover',
    Crop = 'crop',
    Pad = 'pad',
}

// Specifies the most important side
export enum Gravity {
    Auto = 'auto',
    Top = 'top',
    Bottom = 'bottom',
    Left = 'left',
    Right = 'right',
}

// Allows serving of the WebP or AVIF format to browsers that support it
export enum Format {
    Auto = 'auto',
}

// Controls amount of invisible metadata (EXIF data) that should be preserved
export enum Metadata {
    Keep = 'keep',
    Copyright = 'copyright',
    None = 'none',
}

export function imageResizer(url: string, options: Options): string {
    const { publicRuntimeConfig } = getConfig();

    if(!url) return;
    if(url.includes('.svg')) return url;

    if (publicRuntimeConfig.APP_ENV === 'development') {
        return url;
    }

    const defaultOptions = {
        quality: 70,
        format: Format.Auto,
        metadata: Metadata.None,
    };
    const finalOptions: Options = {
        ...defaultOptions,
        ...options,
    };
    const mappedOptions = Object.keys(finalOptions).map(option => `${option}=${finalOptions[option]}`);
    url = `/${url}`;
    url = url.replace('//', '/');

    return `${publicRuntimeConfig.APP_URL}/cdn-cgi/image/${mappedOptions}${url}`;
}

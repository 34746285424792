import { ReactNode } from 'react';

import { Props as ButtonProps } from 'components/layout/Button';
import { Props as LayoutContainerProps } from 'components/layout/LayoutContainer';

import Component from './component';

export interface Props {
    layoutContainerProps?: LayoutContainerProps;
    contentLayout?: Layouts;
    headline?: string;
    headlineMobile?: string;
    tooltip?: string;
    subheadline?: string;
    description?: string;
    children?: ReactNode
    copyButton?: ButtonProps;
    buttonProps?: ButtonProps;
    buttonLayout?: Layouts;
    style?: Styles;
    icon?: string;
    iconAlt?: string;
    onClick?: () => any;
}

export enum Layouts {
    Center = 'center',
    Left = 'left',
}

export enum Styles {
    Primary = 'primary',
    Secondary = 'secondary',
}

Component.defaultProps = {
    headline: null,
    headlineMobile: null,
    subheadline: null,
    description: null,
    children: null,
    layoutContainerProps: {},
    buttonProps: null,
    copyButton: null,
    contentLayout: Layouts.Center,
    buttonLayout: Layouts.Center,
    style: Styles.Primary,
    icon: null,
    onClick: null,
};

export default Component;
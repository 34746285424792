import { Types } from 'models/Subscriber';

import { Props as ButtonProps } from 'components/layout/Button';

import Component from './component';

export interface Errors {
    email: string;
    userAgrees: string;
}

export interface Props {
    input: {
        email: {
            label: string;
            placeholder: string;
        };
        agreement: {
            label: string;
            placeholder: string;
        };
    }
    buttonProps: ButtonProps;
    type: Types;
    onSuccess?: (response: any) => any;
}

Component.defaultProps = {
    onSuccess: null,
};

export default Component;
import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    position: relative;
    background: transparent;

    .copy {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .icon {
            padding-bottom: 1.1em;
        }

        .headline {
            font-size: 2em;
            font-weight: 700;
            color: #000000;
        }
        .main-container {
            display: flex;
            align-items: center;
        }
        .layout-tooltip {
            margin-left: 0.5em;
            max-width: max-content;
        }
        .tooltip-text {
            max-width: 30em;
        }

        .headline-mobile {
            display: none;
            font-size: 2em;
            font-weight: 700;
            color: #000000;
        }

        .subheadline {
            font-size: 1.9em;
            font-weight: 400;
            color: #000000;
        }

        .headline, .subheadline, .headline-mobile {
            .underline {
                position: relative;
                width: 100%;
                z-index: 2;

                .background-small-yellow {
                    position: absolute;
                    width: 105%;
                    height: 100%;
                    top: 0;
                    left: -1%;
                    background-image: url(images/home/underlines/underline-small.svg);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;

                    z-index: -1;
                }

                .background-small-green {
                    position: absolute;
                    width: 110%;
                    height: 100%;
                    top: 0;
                    left: -5%;
                    background-image: url(images/home/underlines/underline-small-green.svg);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;

                    z-index: -1;
                }

                .background-medium-green {
                    position: absolute;
                    width: 110%;
                    height: 100%;
                    top: 0;
                    left: -3%;
                    background-image: url(images/home/underlines/underline-medium-green.svg);
                    background-repeat: no-repeat;
                    background-position: bottom center;
                    background-size: contain;

                    z-index: -1;
                }

                .background-medium {
                    position: absolute;
                    width: 105%;
                    height: 100%;
                    top: 0;
                    left: -1%;
                    background-image: url(images/home/underlines/underline-medium.svg);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;

                    z-index: -1;
                }

                .background-large-yellow {
                    position: absolute;
                    width: 108%;
                    height: 100%;
                    top: 0;
                    left: -3%;
                    background-image: url(images/home/underlines/underline-yellow-large.svg);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;

                    z-index: -1;
                }
                .background-large-green {
                    position: absolute;
                    width: 108%;
                    height: 100%;
                    top: 0;
                    left: -3%;
                    background-image: url(/images/home/underlines/underline-large-green.svg);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;

                    z-index: -1;
                }
            }
        }

        .description {
            margin: 1em 0 2em 0;
            font-size: 1.1em;
            color: #000000;
        }

        .link {
            font-size: .9em;
            color: #BA9449;
            text-decoration: none;
            text-transform: uppercase;
            letter-spacing: .1em;
            margin-top: 3em;

            display: flex;
            justify-content: center;
            align-items: center;

            .icon {
                margin-left: 1em;
            }

            &:hover {
                .icon {
                    transition: transform .3s ease;
                    transform: translateX(1.5em);
                }
            }
        }

        &.layout-center {
            text-align: center;
        }
        &.layout-left {
            text-align: left;
            align-items: flex-start;

            .link {
                justify-content: flex-start;
            }
        }

        &.style-secondary {
            .headline, .subheadline, .description {
                color: #FFFFFF;
            }
        }
    }

    .description-button {
        margin-top: 2em;
        &.layout-center {
            display: flex;
            justify-content: center;
        }
        &.layout-left {
            display: flex;
            justify-content: flex-start;
        }
    }

    @media all and (max-width: ${variables.mobileL}) {}

    @media all and (max-width: ${variables.mobileM}) {
        .copy {
            .headline {
                display: none;
            }
            .tooltip-main {
            margin-left: 0em;
        }
        .tooltip-text {
            max-width: 10em;
        }
            .headline-mobile {
                font-size: 2em;
                display: block;
                padding: 0 .5em;

                .underline {
                    color: #000000;

                    .background-small-yellow {
                        width: 110%;
                        height: 110%;
                        top: -2%;
                        left: -3%;
                    }

                    .background-medium {
                        width: 110%;
                        height: 200%;
                        top: -20%;
                        left: -4%;
                    }
                }
            }

            .description {
                font-size: 135%;
            }

            &.layout-left {
                text-align: center;
                align-items: center;
            }
        }

        .description-button {
            font-size: 125%;

            .button {
                padding: 1.2em 2.5em;
            }

            &.layout-left {
                justify-content: center;
            }
        }
    }
`;
import { ApiResources } from 'types/apiResources';
import { Endpoints } from 'types/endpoints';

import Subscriber, { Types as SubscriberTypes } from 'models/Subscriber';
import { Types as UserAgreeTypes } from 'models/UserAgree';

import { withVariables } from 'utils/string';
import { request } from 'services/Api';


export interface CreateParams {
    email: string;
    type: SubscriberTypes;
    userAgrees: {
        type: UserAgreeTypes;
        content: string;
        value: boolean;
    }[];
}
export const create = async (params?: CreateParams): Promise<Subscriber> => {
    const response = await request({
        method: 'POST',
        path: withVariables(Endpoints.PublicSubscribers, params),
        requestParams: params,
    });

    const data = response?.payload?.data;
    const resource = data && data[ApiResources.Subscriber];

    if(!data || !resource) {
        throw new Error('Not Found');
    }

    return new Subscriber(resource);
};

export interface ActivationParams {
    token: string;
}
export const activation = async (params?: ActivationParams): Promise<Subscriber> => {
    const response = await request({
        method: 'POST',
        path: withVariables(Endpoints.PublicSubscribersActivation, params),
        requestParams: params,
    });

    const data = response?.payload?.data;
    const resource = data && data[ApiResources.Subscriber];

    if(!data || !resource) {
        throw new Error('Not Found');
    }

    return new Subscriber(resource);
};
import ApiSubscriber from 'types/api/Subscriber';

import Model from 'models/Model';

export enum Types {
    Company = 'company',
    Worker = 'worker',
}

export default class Subscriber implements Model {
    id: string;
    email: string;
    type: Types;

    constructor(data: ApiSubscriber) {
        this.id = data.id;
        this.email = data.email;
        this.type = data.type;
    }
}
import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
	width: 100%;

	.form-inputs {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: 1em;

		.layout-forms-input {
			position: relative;

			.layout-forms-input-wrapper {
				margin-bottom: 0;
			}

			.error-container {
				position: absolute;
				bottom: -2em;
				width: 200%;
			}
		}

		.layout-button {
			margin-left: -0.5em;

			.button {
				height: 3em;
			}
		}
	}

	.positive-info {
		display: flex;
		align-items: center;
        gap: 0.8em;
        color: #21dba7;
	}

	.layout-forms-checkbox {
		padding-top: 1em;

		.layout-forms-input-wrapper {
			.internal-wrapper {
				.input-body {
					align-items: center;
				}
			}
		}
	}

	@media all and (max-width: ${vars.tabletL}) {
	}

	@media all and (max-width: ${vars.mobileM}) {
		padding: 0 2em;

		.form-inputs {
			flex-direction: column;

			.layout-forms-input {
				font-size: 150%;
				border-radius: 0.4em;
			}

			.layout-button {
				width: 100%;
				margin-left: 0;
				padding-top: 2em;
				font-size: 130%;

				.button {
					width: 100%;
					padding: 1.8em 0;
				}
				span {
					margin:-1em 0;
				}
			}
		}

		.layout-forms-checkbox {
			font-size: 150%;
		}
	}
`;

import { FunctionComponent } from 'react';
import Link from 'next/link';
import classnames from 'classnames';

import { imageResizer } from 'utils/image';

import ExclamationIcon from 'theme/icons/info.svg';

import Button from 'components/layout/Button';
import Tooltip from 'components/layout/Tooltip/component';

import { Props } from './index';
import StyledComponent from './styles';

const PublicLayoutDescription: FunctionComponent<Props> = ({
    contentLayout,
    headline,
    headlineMobile,
    subheadline,
    tooltip,
    description,
    copyButton,
    children,
    buttonProps,
    buttonLayout,
    style,
    icon,
    iconAlt,
    onClick,
}) => {
    return (
        <StyledComponent
            className="public-layout-description"
        >
            {(headline || subheadline || description || copyButton) && (
                <div
                    className={classnames(
                        'copy',
                        `layout-${contentLayout}`,
                        `style-${style}`
                    )}
                >
                    {icon && (
                        <img
                            className="icon"
                            src={imageResizer(icon, { width: 250 })}
                            loading="lazy"
                            alt={iconAlt}
                        />
                    )}
                    {(headline || headlineMobile) && (
                        <div className='main-container'>
                            {headline && (
                                <h2
                                    className="headline"
                                    dangerouslySetInnerHTML={{ __html: headline }}
                                />
                            )}
                            <h2
                                className="headline-mobile"
                                dangerouslySetInnerHTML={{ __html: headlineMobile ? headlineMobile : headline }}
                            />
                            {tooltip && (
                                <Tooltip
                                    name="recommended"
                                    text={tooltip}
                                    effect="solid"
                                    place='bottom'
                                >
                                    <img
                                        src={ExclamationIcon}
                                        alt="Więcej informacji"
                                        width="24"
                                        height="24"
                                    />
                                </Tooltip>
                            )}
                        </div>
                    )}

                    {subheadline && (
                        <div
                            className="subheadline"
                            dangerouslySetInnerHTML={{ __html: subheadline }}
                        />
                    )}
                    {description && (
                        <div
                            className="description"
                            dangerouslySetInnerHTML={{ __html: description }}
                        />
                    )}
                    {copyButton && (
                        <Link
                            href={copyButton?.href}
                            prefetch={false}
                        >
                            <a className="link">
                                <div
                                    className="link-title"
                                    dangerouslySetInnerHTML={{ __html: copyButton.children }}
                                />
                                {copyButton?.icon?.value && (
                                    <img
                                        className="icon"
                                        src={copyButton?.icon?.value}
                                        loading="lazy"
                                        alt={copyButton?.icon?.alt}
                                    />
                                )}
                            </a>
                        </Link>
                    )}
                </div>
            )}


            {children}

            {buttonProps && (
                <Button
                    className={classnames(
                        'description-button',
                        `layout-${buttonLayout}`
                    )}
                    onClick={onClick}
                    {...buttonProps}
                />
            )}
        </StyledComponent>
    );
};

export default PublicLayoutDescription;
import ApiArticleAuthor from 'types/api/ArticleAuthor';

import FileUpload from 'models/FileUpload';
import Model from 'models/Model';

export default class ArticleAuthor implements Model {
    id: string;
    firstName: string;
    lastName: string;
    image: FileUpload;
    description: string;
    fullName: string;

    constructor(data: ApiArticleAuthor) {
        this.id = data.id;
        this.firstName = data.firstName || '';
        this.lastName = data.lastName || '';
        this.image = data.image && new FileUpload(data.image);
        this.description = data.description;
        this.fullName = `${this.firstName} ${this.lastName}`;
    }
}
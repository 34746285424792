import ApiArticle from 'types/api/Article';
import { Locales } from 'types/locale';
import { Option } from 'types/options';
import { Routes } from 'types/routes';

import ArticleAuthor from 'models/ArticleAuthor';
import ArticleCategory from 'models/ArticleCategory';
import FileUpload from 'models/FileUpload';
import Job from 'models/Job';
import Model from 'models/Model';

import { select as selectLocale } from 'utils/locale';
import { withVariables } from 'utils/string';

const translationsModels = selectLocale({
    [Locales.Pl]: require('locales/models/pl.json'),
});

export enum Types {
    Default = 'default',
    Video = 'video',
    Audio = 'audio',
}

export enum Roles {
    Worker = 'worker',
    Company = 'company',
}

export default class Article implements Model {
    id:string;
    title: string;
    slug: string;
    lead: string;
    content: string;
    enabled: boolean;
    enabledOption: Option<boolean>;
    type: Types;
    typeOption: Option<Types>;
    role: Roles;
    category: ArticleCategory;
    coverImage: FileUpload;
    href: string;
    imageAltText: string;
    metaTitle: string;
    metaDescription: string;
    articleAuthor: ArticleAuthor;
    jobs: Job[];
    similarArticles: Article[];
    ogPhoto: FileUpload;
    isSponsored: boolean;
    sponsoredCompanyName: string;

    constructor(data: ApiArticle) {
        this.id = data.id;
        this.title = data.title;
        this.slug = data.slug;
        this.lead = data.lead;
        this.content = data.content;
        this.type = data.type;
        this.role = data.role;
        this.typeOption = this.getTypeOption(this.type);
        this.category = data.category && new ArticleCategory(data.category);
        this.coverImage = data.coverImage && new FileUpload(data.coverImage);
        this.href = this.getHref();
        this.imageAltText = data.imageAltText;
        this.metaTitle = data.metaTitle;
        this.metaDescription = data.metaDescription;
        this.articleAuthor = data.articleAuthor && new ArticleAuthor(data.articleAuthor);
        this.jobs = data.jobs && Array.isArray(data.jobs)
            ? data.jobs.map(element => new Job(element))
            : [];
        this.similarArticles = data.similarArticles && Array.isArray(data.similarArticles)
            ? data.similarArticles.map(article => new Article(article))
            : [];
        this.ogPhoto = data.ogPhoto && new FileUpload(data.ogPhoto);
        this.isSponsored = data.isSponsored;
        this.sponsoredCompanyName = data.sponsoredCompanyName;
    }

    getTypeOption = (value: Types): Option<Types> => {
        return typeOptions.find(option => option.value === value);
    };

    getHref = (): string => {
        switch (this.role) {
            case Roles.Worker:
                return withVariables(
                    Routes.PublicBlogPost,
                    {
                        articleCategorySlug: this.category?.slug,
                        slug: this.slug,
                    }
                );
            case Roles.Company:
                return withVariables(
                    Routes.PublicBlogCompanyPost,
                    { slug: this.slug }
                );
            default:
                return Routes.PublicHome;
        }
    };
}

export const typeOptions = [{
    value: Types.Default,
    label: translationsModels?.article?.types?.default,
}, {
    value: Types.Video,
    label: translationsModels?.article?.types?.video,
}, {
    value: Types.Audio,
    label: translationsModels?.article?.types?.audio,
}];
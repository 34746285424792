import { FunctionComponent, useState } from 'react';
import classnames from 'classnames';

import { Locales } from 'types/locale';

import { Types } from 'models/UserAgree';

import { ERROR_NEWSLETTER_YOU_ARE_SAVE } from 'consts/errors';
import { isBrowser } from 'utils/browser';
import { select as selectLocale } from 'utils/locale';
import Tracking, { SmEventDescriptons, SmEventTypes } from 'services/Tracking';

import { create } from 'requests/subscriber';

import CheckIcon from 'theme/icons/circle-check.svg';

import Button, { ButtonTypes } from 'components/layout/Button';
import Checkbox from 'components/layout/forms/Checkbox';
import Form from 'components/layout/forms/Form';
import Input from 'components/layout/forms/Input';

import { Errors, Props } from './index';
import StyledComponent from './styles';

const PublicLayoutFormsNewsletterForm: FunctionComponent<Props> = ({
    input,
    buttonProps,
    type,
    onSuccess,
}) => {
    const [errorMessage, setErrorMessage]: [Errors, Function] = useState(null);

    const translations = selectLocale({
        [Locales.Pl]: require('./locales/pl.json'),
    });

    return (
        <StyledComponent
            className={classnames(
                'public-layout-form-newsletter-form',
            )}
        >
            <Form
                submitAction={async (values) => {
                    try {
                        const response = await create({
                            email: values?.email,
                            type,
                            userAgrees: values?.userAgrees && [{
                                type: Types.EmailMarketing,
                                content: input?.agreement?.label,
                                value: values?.userAgrees,
                            }],
                        });

                        return response;
                    } catch (error) {
                        console.error(error);
                        setErrorMessage(error?.payload?.originalError?.data?.errors);
                        throw error;
                    }
                }}
                onSuccess={(response) => {
                    onSuccess({});

                    response?.email && isBrowser() && Tracking.salesManago(
                        response?.email,
                        SmEventTypes.Other,
                        SmEventDescriptons.Newsletter,
                        window.location?.toString(),
                    );
                }}
            >
                {({ formik }) => {
                    return (
                        <>
                            <div className="form-inputs">
                                <Input
                                    name="email"
                                    label={input?.email?.label}
                                    placeholder={input?.email?.placeholder}
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    error={errorMessage?.email === ERROR_NEWSLETTER_YOU_ARE_SAVE ? '' : errorMessage?.email}
                                />
                                <Button
                                    {...buttonProps}
                                    loading={formik.isSubmitting}
                                    type={ButtonTypes.Submit}
                                />
                            </div>
                            { errorMessage?.email === ERROR_NEWSLETTER_YOU_ARE_SAVE && (
                                <div className="positive-info">
                                    <img
                                        src={CheckIcon}
                                        alt=""
                                        loading="lazy"
                                    />
                                    <p>{translations?.alreadySaved}</p>
                                </div>
                            )}
                            <Checkbox
                                name="userAgrees"
                                label={input?.agreement?.label}
                                onChange={formik.handleChange}
                                value={formik.values.userAgrees}
                                error={errorMessage?.email === ERROR_NEWSLETTER_YOU_ARE_SAVE ? '' : errorMessage?.userAgrees}
                                expandable
                            />
                        </>
                    );
                }}
            </Form>
        </StyledComponent>
    );
};

export default PublicLayoutFormsNewsletterForm;